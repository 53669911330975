import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { matchesAnyPrefix, matchesAnySuffix } from '../../utils/helpers/misc';
import { toStringArray } from '../../utils/env';
import { getDomainParts } from '../../utils/helpers/linkHelpers';

export function makeLink({
  href,
  absoluteUrl,
  alwaysPrependBasePath,
  basePath,
  origin,
  knownServicePortalUrls,
  stageDependentUrls,
}: {
  origin: string;
  basePath: string;
  href?: string;
  absoluteUrl?: boolean;
  alwaysPrependBasePath?: boolean;
  knownServicePortalUrls?: string[];
  stageDependentUrls?: string[];
}): string | undefined {
  if (href === undefined) {
    return;
  }

  let targetHref = href;

  // Make internal service portal links relative
  if (matchesAnyPrefix(targetHref, knownServicePortalUrls)) {
    let pattern = (knownServicePortalUrls ?? []).find((url) =>
      href.startsWith(url),
    );

    if (pattern === undefined) {
      return href;
    }

    if (pattern.endsWith('/')) {
      pattern = pattern.substring(0, pattern.length - 1);
    }

    targetHref = href.replace(pattern, '');
  }

  // Handle relative links
  if (targetHref.startsWith('/')) {
    let res = targetHref;

    if (alwaysPrependBasePath) {
      res = `${basePath}${res}`;
    }

    if (absoluteUrl) {
      res = res.startsWith(basePath)
        ? `${origin}${res}`
        : `${origin}${basePath}${res}`;
    }

    return res;
  }

  // Handle links that are subject to stage dependant transformation
  if (matchesAnyPrefix(href, stageDependentUrls)) {
    const targetUrl = new URL(href);
    const originUrl = new URL(origin);

    const targetParts = getDomainParts(href);
    const originParts = getDomainParts(origin);

    targetUrl.hostname = `${targetParts?.sub ?? ''}.${originParts?.base ?? ''}`;
    targetUrl.port = originUrl.port;
    targetUrl.protocol = originUrl.protocol;

    return targetUrl.toString();
  }

  // The rest remains unaltered
  return href;
}

type MakeLinkFuncParams = {
  /**
   * The link to transform
   */
  href?: string;

  /**
   * Always prepend the Next.js basePath
   */
  alwaysPrependBasePath?: boolean;

  /**
   * Always return as an absolute URL including protocol
   */
  absoluteUrl?: boolean;
};

export type MakeLinkFunc = (params: MakeLinkFuncParams) => string | undefined;

export function useMakeLink(): MakeLinkFunc {
  const { basePath } = useRouter();
  const [origin, setOrigin] = useState(process.env.NEXT_PUBLIC_HOST ?? '');

  const knownServicePortalUrls = toStringArray(
    process.env.NEXT_PUBLIC_KNOWN_PORTAL_URLS,
  );
  const stageDependentUrls = toStringArray(
    process.env.NEXT_PUBLIC_STAGE_DEPENDENT_URLS,
  );
  const stageOrigins = toStringArray(process.env.NEXT_PUBLIC_STAGE_ORIGINS);

  useEffect(() => {
    const { origin, hostname } = new URL(window.location.href);

    if (
      stageDependentUrls.length > 0 &&
      matchesAnySuffix(hostname, stageOrigins)
    ) {
      setOrigin(origin);
    }
  }, [stageDependentUrls, stageOrigins]);

  return ({ href, absoluteUrl, alwaysPrependBasePath }) =>
    makeLink({
      href,
      origin,
      basePath,
      absoluteUrl,
      alwaysPrependBasePath,
      knownServicePortalUrls,
      stageDependentUrls,
    });
}
