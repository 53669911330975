import { toBoolean } from './helpers/misc';

export type EnvironmentProps = {
  features: {
    forceTracking: boolean;
    enableChat: boolean;
  };
};

export function toBool(input?: string): boolean {
  const baseResult = toBoolean(input);
  if (baseResult === undefined) {
    return false;
  }

  return baseResult;
}

export function toIntOrNull(input?: string): number | null {
  if (input === undefined) {
    return null;
  }

  const parsedInput = parseInt(input || '');

  if (isNaN(parsedInput)) {
    return null;
  }

  return parsedInput;
}

export function toInt(input?: string): number | undefined {
  if (input === undefined) {
    return undefined;
  }

  return parseInt(input);
}

export function toStringArray(input?: string, delimiter = ','): string[] {
  return (input ?? '')
    .split(delimiter)
    .map((value) => value.trim())
    .filter((value) => value !== undefined && value !== null && value !== '');
}

export function toNumberArray(
  input?: string,
  delimiter = ',',
): Array<number | null> {
  return (input ?? '')
    .split(delimiter)
    .map((value) => value.trim())
    .map(toIntOrNull);
}

export function getEnvProps(): EnvironmentProps {
  return {
    features: {
      enableChat: toBool(process.env.ENABLE_CHAT),
      forceTracking: toBool(process.env.FORCE_TRACKING),
    },
  };
}
