import { LinkProps } from '@components/Link';
import { Metadata, DeploymentStage } from '@utils/DataFetcher';

export const umlauts = 'äöüß';
export const umlautsReplacements = ['ae', 'oe', 'ue', 'ss'];
export const uml = new RegExp(umlauts.split('').join('|'), 'g');

export function slugify(str: string, lang?: string) {
  const language = lang ?? 'de';

  const a = 'àáäâãåăæçèéëêǵḧìíïîḿńǹñòóöôœøṕŕśșțùúüûǘẃẍÿź·/_,:;';
  const b = 'aaaaaaaaceeeeghiiiimnnnooooooprsstuuuuuwxyz------';
  const p = new RegExp(a.split('').join('|'), 'g');
  return str
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(uml, (c) => {
      return umlautsReplacements[umlauts.indexOf(c)];
    }) // replace umlauts
    .replace(p, (c) => {
      return b.charAt(a.indexOf(c));
    }) // Replace special characters
    .replace(/&/g, language === 'de' ? '-und-' : '-and-') // Replace &
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
}

function isExceptionalLink(href: string) {
  const url = new URL(href, `https://${currentHost}`);
  // a telephone or smth else
  if (url.protocol !== 'https:' && url.protocol !== 'http:') return true;
  // a resource
  const rgx = new RegExp(
    '.(jpg|jpeg|png|gif|pdf|css|js|mp3|mp4|zip|rar|txt|webp|svg)$',
  );
  if (href.match(rgx)) return true;

  return false;
}

export function slashify(url: string | undefined) {
  if (!url) {
    return '';
  }
  // dont add trailing slash to exceptions
  if (isExceptionalLink(url)) {
    return url;
  }
  return url.endsWith('/') ? url : url + '/';
}

export function removeLeadingSlash(url: string): string {
  if (url.startsWith('/')) {
    return url.substring(1);
  }
  return url;
}

export function ensureTrailingSlash(url: string): string {
  if (url === '' || url.endsWith('/')) {
    return url;
  }
  return `${url}/`;
}

export const hosts: Record<Metadata['basename'], string> = {
  /** NOTE(filipe): the cds-wissen host is NOT wrong because we gotta handle links with this old host.
   * You'll see that the new host (commerzbank.de/service) case is handled down below
   */
  'cds-wissen': 'service.commerzbank.de',
  'cds-wissen-gpp-de': 'service-firmenkunden.commerzbank.com',
  'cds-wissen-gpp-en': 'service-corporates.commerzbank.com',
};

const basename = process.env.NEXT_PUBLIC_WEBAPP_BASENAME || 'cds-wissen';

export const currentHost = hosts[basename as Metadata['basename']];

export type MakeStagedLinkFunc = (
  defaultLink: string,
  stages?: Partial<Record<DeploymentStage, string>>,
) => string;

export function makeStagedLinkFunction(
  deploymentStage: DeploymentStage,
): MakeStagedLinkFunc {
  return (defaultLink, stages) => {
    if (stages === undefined) {
      return defaultLink;
    }

    return stages[deploymentStage] ?? defaultLink;
  };
}

type DomainParts = {
  sub?: string;
  base: string;
};

export function getDomainParts(testHostname: string): DomainParts | undefined {
  const knownSubdomains = ['www', 'kunden'];

  try {
    const { hostname } = new URL(testHostname);

    const [tld, domain, ...rest] = hostname.split('.').reverse();

    const [sub, ...newRest] = rest.reverse();

    if (knownSubdomains === undefined || knownSubdomains.includes(sub)) {
      const base = [...newRest, domain, tld]
        .filter((x) => x !== null && x !== undefined)
        .join('.');

      return { sub, base };
    } else {
      const base = [sub, ...newRest, domain, tld]
        .filter((x) => x !== null && x !== undefined)
        .join('.');

      return { base };
    }
  } catch (e) {
    return undefined;
  }
}

export function isExternalLink(url?: string): boolean {
  return (url?.startsWith('http://') || url?.startsWith('https://')) ?? false;
}

export function makeLinkTargetProps(
  url?: string,
): Pick<LinkProps, 'target' | 'rel'> {
  if (isExternalLink(url)) {
    return {
      target: '_blank',
      rel: 'noopener noreferrer',
    };
  }

  return {};
}
