import { DomElement } from 'html-react-parser';

export function toBoolean(input?: string): boolean | undefined {
  if (input === undefined) {
    return undefined;
  }

  switch (input.toLowerCase()) {
    case 'true':
    case 'yes':
    case '1':
      return true;
    default:
      return false;
  }
}

const umlautsMap: { [key: string]: string } = {
  ä: 'ae',
  ö: 'oe',
  ü: 'ue',
  ß: 'ss',
};

export function replaceUmlauts(str: string): string {
  return str.replace(/[äöüß]/g, (c) => umlautsMap[c] || c);
}

// function to deliver specific keywords from user feedback responses to GA4.
export function findSubstrings(
  strings: Set<string>,
  searchTerms: string[],
): string[] {
  const searchSet = new Set(searchTerms.map((term) => term.toLowerCase()));
  const foundTerms = new Set<string>();

  strings.forEach((str) => {
    const lowerStr = str.toLowerCase();
    searchSet.forEach((term) => {
      if (lowerStr.includes(term) && !foundTerms.has(term)) {
        foundTerms.add(term);
      }
    });
  });

  return Array.from(foundTerms);
}

const translationMap: { [key: string]: string } = {
  unverständlich: 'not_understandable',
  'passt nicht': 'does_not_match',
  unvollständig: 'incomplete',
  undeutlich: 'not_clear',
  sonstiges: 'others',
};

// function to deliver user feedback responses as the desired key, value object to BG
export function generateFeedbackBooleanObject(
  substrings: string[],
  foundSubstrings: string[],
): { [key: string]: boolean } {
  const lowercasedFoundSet = new Set(
    foundSubstrings.map((term) => term.toLowerCase()),
  );

  return substrings.reduce(
    (result, substr) => {
      const translation = translationMap[substr] || substr;
      result[translation] = lowercasedFoundSet.has(substr.toLowerCase());
      return result;
    },
    {} as { [key: string]: boolean },
  );
}

export const findFirstDataNode = (domNode: DomElement): string | null =>
  domNode.data ||
  domNode.children?.map(findFirstDataNode).find(Boolean) ||
  null;

export function matchesAnyPrefix(
  target: string,
  prefixes: string[] = [],
): boolean {
  return prefixes.some((prefix) => target.startsWith(prefix));
}

export function matchesAnySuffix(
  target: string,
  suffixes: string[] = [],
): boolean {
  return suffixes.some((suffix) => target.endsWith(suffix));
}
